.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 5rem;
  grid-row-gap: 0px;
  margin: 2rem;
  padding: 4rem;
  max-height: 83vh;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align the items for better presentation */
  justify-content: center;
}

.storyHeading{
  margin-bottom: 3rem;
}

.tags{
  margin-bottom: 1rem;
  color: var(--grey-colour);
}

.smallerText{
  margin-bottom: 3rem;
}


.storyHeading, .storyDetails, .tags, .smallerText, .buttonContainer {
  width: 100%; /* Full width for all child components */
}
