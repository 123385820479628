/* ColorGrid.module.css */
.parent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 30rem;
  width: 100%;
}

.div1 {
  border-radius: 10px 10px 0 0;
  grid-area: 1 / 1 / 2 / 2; /* Adjusted to correct grid area definition */
  background-color: black;
  color: white;
  display: flex;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  height: 100%; /* Optional: Adjust height to ensure it fills the container */
}

.div2 {
  border-radius: 0 0 10px 10px;
  grid-area: 2 / 1 / 3 / 2; /* Adjusted to correct grid area definition */
  background-color: white;
  color: black;
  display: flex;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  height: 100%; /* Optional: Adjust height to ensure it fills the container */
}
