.gridContainer {
    width: 100%;
    height: 80rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5rem;
    grid-row-gap: 3rem;
    margin-top: 3rem;
    padding-right: 6rem;
    padding-left: 6rem;
    overflow: hidden; /* Ensures no overflow from the grid container itself */
}

.div1, .div2, .div3, .div4 {
    border-radius: 10px;
    overflow: hidden; /* Confirms no overflow from each image container */
}

.div1 {
    grid-area: 1 / 1 / 2 / 3; /* First row, spans two columns */
}

.div2 {
    grid-area: 2 / 1 / 3 / 3; /* Second row, spans two columns */
}

.div3 {
    grid-area: 1 / 3 / 2 / 5; /* First row, spans the last two columns */
}

.div4 {
    grid-area: 2 / 3 / 3 / 5; /* Second row, spans the last two columns */
}

.fullSizeImage {
    width: 100%; /* Ensures the image takes full width of its container */
    height: 100%; /* Ensures the image takes full height of its container */
    object-fit: cover; /* Ensures the image covers the div completely */
}

.videoAltText {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.794); /* Semi-transparent background */
    padding: 10px;
    margin-top: -3rem; /* Adjust this as needed to position the text over the video or below it */
    position: relative;
    z-index: 10;
    font-size: 1.2rem;
}
