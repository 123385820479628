.aboutContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5rem;
  margin-bottom: 3rem;
}

.div1 { 
    grid-area: 1 / 1 / 2 / 3; /* Takes up the first two columns */
  }
  
  .div2 { 
    grid-area: 1 / 3 / 2 / 5; /* Takes up the last two columns */
  }

  .p1{
    margin-bottom: 1rem;
  }