.header {
    position: relative; /* Needed to position Lottie absolutely inside this container */
    height: 100vh;
    width: 100%;
    padding: 6rem;
    background-color: var(--light-colour);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.lottie-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:0; /* Sends it to the back */
}

.title-container {
    color: var(--light-colour);
    mix-blend-mode: difference;
    z-index: 100; /* Ensures it's above the Lottie animation */
}

.container {
    position: relative; /* Brings it above the Lottie background */
    width: 100%;
}

.inline-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.svg-one {
    width: 8vw; /* This will make the SVG width responsive to the viewport width */
    height: auto; /* Maintain aspect ratio */
    margin: 0 10px;
    mix-blend-mode: difference;
}


@media (max-width: 600px) {
    .inline-container {
        flex-direction: column;
    }
}
