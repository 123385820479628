@import url('https://fonts.cdnfonts.com/css/tt-autonomous-trl');
@import url('https://fonts.cdnfonts.com/css/manrope');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --light-colour: #CFCFCF;
  --dark-colour: #141414; 
  --grey-colour: rgba(101, 101, 101, 0.786); 

  --font-size-h1: 10.42vw;
  --font-size-h2: 5.21vw;
  --font-size-h3: 1.15vw;
  --font-size-h4: 1vw;

  --font-bold:500;
  --font-normal:300;

}

body::-webkit-scrollbar {
  display: none;
}

html{
  height: -webkit-fill-available;
  background-color: var(--light-colour);
  scroll-behavior: smooth;
}

/* margin-top: 3rem;
margin-top: 6rem; */

body {
  color: var(--dark-colour);
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: var(--font-normal);
  font-size: var(--font-size-h3);
  line-height: 120%;
}

h1{
  font-family: 'TT Autonomous Trial Variable', sans-serif;
  font-size: var(--font-size-h1);
  line-height: 90%;
  text-transform: uppercase;
  color: var(--light-colour);
}

h2{
  font-size: var(--font-size-h2);
  text-transform: uppercase;
  font-weight: var(--font-bold);
  line-height: 90%;
  }

  h3{
    font-size: var(--font-size-h3);
    font-weight: 500;
    line-height: 120%;
    }

    h4{
      font-size: var(--font-size-h4);
      color: var(--grey-colour);
      text-transform: uppercase;
      font-weight: 500;
      line-height: 120%;
      }