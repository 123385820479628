.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 5rem;
  grid-row-gap: 0px;
  padding-left: 6rem;
  padding-right: 6rem;
  margin-bottom: 1rem;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
}

.storyHeading{
  margin-bottom: 3rem;
}

.tags{
  margin-bottom: 1rem;
  color: var(--grey-colour);
}

.smallerText{
  margin-bottom: 3rem;
}


.storyHeading, .storyDetails, .tags, .smallerText, .buttonContainer {
  width: 100%; /* Full width for all child components */
}

.buttonContainer {
}

/* Styles for the image */
.image {
  border-radius: 10px;
  width: 100%; /* Full width */
  max-height:70vh ; /* Maintain aspect ratio */
}

.tag{
  margin-top: 1rem;
}