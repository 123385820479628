.container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index:0; /* Sends it to the back */
    padding: 2rem;
}

.canvas {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
